import React from 'react';
import { Link } from 'gatsby';

const FourServices = () => {
  const serviceBox = (serviceText, linkRef) => {
    return (
      <Link to={linkRef} ><div className="service-button button call-back-submit">{serviceText}</div></Link>
    )
  }


  return (
    <section className="section quicklinks-section mx-1">
      <div className="container">
        <div className="quicklinks-header is-clearfix">
          <h2 className="is-pulled-left">Quicklinks</h2>
          <p className="is-pulled-left">We have extensive experience in a wide range of business recovery solutions, explore some of our top guides below</p>
        </div>
        <div className="four-service-bar columns">
          <div className="four-service column">{serviceBox("How To Close A Company", "/how-to-close-your-company")}</div>
          <div className="four-service light-blue column">{serviceBox("Liquidation Costs", "/liquidation-costs")}</div>
          <div className="four-service column">{serviceBox("Directors' Duties", "/directors-duties")}</div>
          <div className="four-service light-blue column">{serviceBox("Redundancy", "/redundancy")}</div>
        </div>

      </div>

    </section>
  )

}

export default FourServices;