import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
const FrontInsights = () => {
    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark(
                filter: {
                  frontmatter:{
                    category:{
                      eq: "Insolvency Insights"
                    }
                  }
                }
                limit:3
              ) {
                edges {
                    node {
                        frontmatter {
                            title
                            image {
						        childImageSharp {
                                    fluid(maxWidth: 404, maxHeight: 280, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                        }
          }
                        }
                        fields {
                            slug
                        }
                        excerpt(pruneLength:200)
                    }
                }
            }
        }
    `)



    return (
        <section className="section">
            <div className="container">

                <div className="columns">
                    <div className="column is-12 no-padding-top">
                        <h2 className="news-title is-size-4">
                            Insolvency Insights
						</h2>
                        <div className="columns">
                            {data.allMarkdownRemark.edges.map((edge) => {
                                return (
                                    <div className="column is-4">
                                        <article>
                                            <header className="insights-header">
                                                <div className="image-thumbnail content">
                                                    <Link to={edge.node.fields.slug}>
                                                        <Img fluid={edge.node.frontmatter.image.childImageSharp.fluid} />
                                                    </Link>
                                                </div>
                                                <div className="post-meta content home-insights-title-box">
                                                    <Link className="title is-size-4 content home-insights-title" to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
                                                </div>
                                            </header>
                                            <p className="insights-blurb">
                                                {edge.node.excerpt}
                                            </p>
                                        </article>
                                    </div>
                                )
                            })}



                        </div>


                    </div>
                </div>

            </div>
        </section>


    )
}

export default FrontInsights;