import React from 'react';
import { Link } from 'gatsby'
import { FaCheck } from 'react-icons/fa'
// import './style.scss';



const FiftyFifty = () => (
    <section className="section fiftyfifty is-paddingless">

        <div className="columns">
            <div className="column is-1 fifty-left-spacer"></div>
            <div className="column fifty-left">
                <h1 className="title">
                    Why businesses choose us
                    </h1>
                <p>
                    We know the difficulties businesses experience in the current economic climate and the pressure directors find themselves under.
                    Because of this, we will listen to you and understand your situation in order to work with you to find the right solution for you
                    and your business.
                    </p>
                <br />
                <Link className="cta-button button" to="/contact-us">Contact Us</Link>
            </div>
            <div className="column is-1 fifty-left-spacer"></div>
            <div className="column is-1 fifty-right-spacer"></div>
            <div className="column fifty-right">
                <h1 className="title">
                    Our Service
                    </h1>

                <ul className="fa-ul">
                    <li><FaCheck size={12} color={"#fff"} class="our-service-check" />Free initial advice</li>
                    <li><FaCheck size={12} color={"#fff"} class="our-service-check" />Advice tailored to your business circumstances</li>
                    <li><FaCheck size={12} color={"#fff"} class="our-service-check" />Understanding and supportive attitude to you and your business</li>
                    <li><FaCheck size={12} color={"#fff"} class="our-service-check" />Network of specialist partners able to provide comprehensive support</li>
                    <li><FaCheck size={12} color={"#fff"} class="our-service-check" />Detailed advice on all of the business rescue options available to you</li>
                    <li><FaCheck size={12} color={"#fff"} class="our-service-check" />Speak directly to a qualified insolvency professional</li>
                </ul>
            </div>
            <div className="column is-1 fifty-right-spacer"></div>
        </div>

    </section>
);


export default FiftyFifty;