import React from 'react';
// import HomeHeader from '../components/home-header'
import FrontStories from '../components/front-client-stories'
import Footer from '../components/footer';
import '../components/style.scss';
import FrontInsights from '../components/front-insights';
import LetterBoxCall from '../components/letterbox-call';
import SavingBlock from '../components/saving-block';
import FiftyFifty from '../components/fiftyfifty';
import ServiceTiles from '../components/service-tiles';
import HomeHeaderV2 from '../components/home-header-v2';
import FourServices from '../components/four-services';

const IndexPage = () => (
    <div>
        {/* <HomeHeader /> */}
        <HomeHeaderV2 />
        <FourServices />
        <ServiceTiles />
        <FrontInsights />
        <FrontStories />
        <LetterBoxCall />
        <SavingBlock />
        <FiftyFifty />
        <Footer />
    </div>
)

export default IndexPage;
