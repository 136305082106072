import React from 'react';
import { Link } from 'gatsby'
import sideImg from '../images/shaking-hands.jpg'
// import './style.scss';



const SavingBlock = () => (
    <section className="section saving-block">
        <div className="container">
            <div className="columns">
                <div className="column is-two-thirds content">
                    <h1 className="title">
                        Saving your business
                    </h1>
                    <h2 className="subtitle">
                        Through experience and understanding
                    </h2>
                    <p>
                        With over 30 years’ experience in company insolvency and business recovery,
                        we have the knowledge and most importantly, the understanding,
                        to be able to assist you whatever your business circumstances may be.
                        We will work with you to find the right solution to help you and your business,
                        be it restructure, recovery or simply helping you to walk away and move on.
                    </p>
                    <Link className="body-button button" to="/contact-us">Get in touch to find out more</Link>
                </div>
                <div className="column is-one-third tih-img">
                    <figure class="image is-3by2">
                        <img
                            src={sideImg}
                            alt="Shaking Hands"
                        />
                    </figure>
                </div>
            </div>
        </div>
    </section>
);


export default SavingBlock;